<template>
  <div>
    <v-dialog :max-width="1200" v-model="showModal" @click:outside="closeModal">
      <v-card class="py-6" style="overflow: hidden">
        <section class="pt-0">
          <v-card-title class="text-h5 secondary--text">Sanciones</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <p class="font-weight-medium primary--text mb-0">
                  Nombre del proveedor:
                </p>
                <p class="text-subtitle-1 font-weight-bold primary--text">
                  {{ providerData.nombre }}
                </p>
                <p class="font-weight-medium primary--text mb-0">
                  Nombre comercial:
                </p>
                <p class="text-subtitle-1 font-weight-bold primary--text">
                  {{ providerData.nombre_comercial }}
                </p>
                <v-img
                  v-if="providerLogo"
                  max-height="242"
                  max-width="242"
                  :src="providerLogo"
                />
                <v-icon v-else color="primary" size="232px">
                  mdi-image-outline
                </v-icon>
              </v-col>
              <v-col cols="12" sm="12" md="9">
                <section>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        ref="select_desc"
                        class="custom-select"
                        label="Sanción a aplicar *"
                        outlined
                        :disabled="readOnlySancion"
                        clearable
                        :value="newSancion.id_descripcion_sancion"
                        item-value="id"
                        return-object
                        :items="tipos_sanciones"
                        @blur="$v.newSancion.id_descripcion_sancion.$touch()"
                        @change="setYearSancion"
                        @click:clear="newSancio.id_descripcion_sancion = null"
                      >
                        <template #item="{ item, on, attrs }">
                          <div
                            class="d-flex flex-column"
                            style="width: -webkit-fill-available"
                          >
                            <div class="cerrado">
                              <p class="mb-0 mx-2">{{ item.nombre }}</p>
                            </div>
                            <v-list-item
                              v-for="desc in item.descripciones"
                              :key="desc.id"
                              v-on="on"
                              v-bind="attrs"
                              class="mx-4"
                              color="blueGrayMinsal"
                              :input-value="
                                desc.id === newSancion?.id_descripcion_sancion
                              "
                            >
                              <v-list-item-title
                                @click="setDescripcionSancion(desc.id)"
                                >{{ desc.nombre }}</v-list-item-title
                              >
                            </v-list-item>
                          </div>
                        </template>
                        <template #selection="{ item }">
                          {{
                            item.descripciones.find(
                              (target) =>
                                target.id === newSancion?.id_descripcion_sancion
                            )?.nombre
                          }}
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        type="number"
                        label="Años por sanción *"
                        outlined
                        min="1"
                        max="5"
                        @keyup="validYears"
                        v-model="selectedYear"
                      />
                    </v-col>
                    <v-col cols="4">
                      <vc-date-picker
                        mode="dateTime"
                        locale="es"
                        :popover="config_calendar"
                        :min-date="lastSancion"
                        v-model="newSancion.fecha_inicio"
                      >
                        <template v-slot="{ inputEvents }">
                          <v-text-field
                            class="border focus:outline-none focus:border-blue-300"
                            label="Fecha y hora de inicio *"
                            readonly
                            :disabled="readOnlySancion"
                            :value="formattedFechaInicio"
                            prepend-inner-icon="mdi-calendar"
                            v-on="inputEvents"
                            outlined
                            :error-messages="fechaInicioErrors"
                            @blur="$v.newSancion.fecha_inicio.$touch()"
                          />
                        </template>
                      </vc-date-picker>
                    </v-col>
                    <v-col cols="4">
                      <vc-date-picker
                        mode="dateTime"
                        locale="es"
                        :popover="config_calendar"
                        :min-date="newSancion.fecha_inicio"
                        v-model="newSancion.fecha_fin"
                      >
                        <template v-slot="{ inputEvents }">
                          <v-text-field
                            class="border focus:outline-none focus:border-blue-300"
                            label="Fecha y hora de fin *"
                            disabled
                            prepend-inner-icon="mdi-calendar"
                            v-on="inputEvents"
                            outlined
                            :value="formattedFechaFin"
                            :error-messages="fechaFinErrors"
                            @blur="$v.newSancion.fecha_fin.$touch()"
                          />
                        </template>
                      </vc-date-picker>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="newSancion.observaciones"
                        :error-messages="observacionesErrors"
                        @input="$v.newSancion.observaciones.$touch()"
                        label="Observaciones *"
                        :disabled="readOnlySancion"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        outlined
                        label="Acta de no cumplimiento *"
                        prepend-icon=""
                        append-icon="mdi-paperclip"
                        accept=".pdf"
                        :disabled="readOnlySancion"
                        v-model="newSancion.documento"
                        :error-messages="documentoErrors"
                        @blur="$v.newSancion.documento.$touch()"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row class="mx-1">
                    <v-btn
                      outlined
                      color="secondary"
                      class="mr-4"
                      @click="closeModal"
                    >
                      {{ readOnlySancion ? "Volver" : "Cancelar" }}
                    </v-btn>
                    <v-btn
                      v-if="!readOnlySancion"
                      color="secondary"
                      class="mr-4"
                      @click="guardarSanciones"
                    >
                      Aplicar
                    </v-btn>
                  </v-row>
                </section>
              </v-col>
            </v-row>
          </v-card-text>
        </section>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
export default {
  name: "ModalSancionesComponent",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    lastSancion: {
      type: String,
      default: null,
    },
    sancionSelected: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    model: 1,
    providerData: {},
    fecha_actual: moment().format("YYYY/MM/DD hh:mm"),
    config_calendar: {
      visibility: "click",
    },
    tipos_sanciones: [],
    newSancion: {
      id_descripcion_sancion: null,
      id_model: null,
      documento: null,
      observaciones: null,
      fecha_inicio: null,
      fecha_fin: null,
      id_proceso_compra: null,
      id_forma_contratacion: null,
    },
    selectedYear: 0,
  }),
  validations: {
    newSancion: {
      id_descripcion_sancion: { required },
      documento: { required },
      observaciones: { required },
      fecha_inicio: { required },
      fecha_fin: { required },
    },
  },
  watch: {
    "newSancion.fecha_inicio"() {
      this.setFechaFin();
    },
    sancionSelected() {
      if (Object.keys(this.sancionSelected).length > 0) {
        Object.keys(this.sancionSelected).forEach((key) => {
          this.newSancion[key] = this.sancionSelected[key];
        });

        let start = moment(this.sancionSelected.fecha_inicio);
        let end = moment(this.sancionSelected.fecha_fin);
        let years = end.diff(start, "years");
        this.setYearSancion({ anios: years });
      } else {
        this.setYearSancion({ anios: 0 });
      }
    },
  },
  computed: {
    ...mapState("proveedores", ["providerLogo"]),
    ...mapState("agregarInsumo", ["selectedProvider"]),
    ...mapState("seguimientoOrden", ["seguimientoContrato"]),
    readOnlySancion() {
      return Object.keys(this.sancionSelected).length > 0;
    },
    descripcionSancionErrors() {
      const errors = [];
      if (!this.$v.newSancion.id_descripcion_sancion.$dirty) return errors;
      !this.$v.newSancion.id_descripcion_sancion.required &&
        errors.push("Sanción a aplicar es obligatorio");
      return errors;
    },
    fechaInicioErrors() {
      const errors = [];
      if (!this.$v.newSancion.fecha_inicio.$dirty) return errors;
      !this.$v.newSancion.fecha_inicio.required &&
        errors.push("Fecha y hora de inicio es obligatorio");
      return errors;
    },
    fechaFinErrors() {
      const errors = [];
      if (!this.$v.newSancion.fecha_fin.$dirty) return errors;
      !this.$v.newSancion.fecha_fin.required &&
        errors.push("Fecha y hora de fin es obligatorio");
      return errors;
    },
    observacionesErrors() {
      const errors = [];
      if (!this.$v.newSancion.observaciones.$dirty) return errors;
      !this.$v.newSancion.observaciones.required &&
        errors.push("Observaciones es obligatorio");
      return errors;
    },
    documentoErrors() {
      const errors = [];
      if (!this.$v.newSancion.documento.$dirty) return errors;
      !this.$v.newSancion.documento.required &&
        errors.push("Acta de no cumplimiento es obligatorio");
      return errors;
    },
    formattedFechaInicio() {
      return this.newSancion.fecha_inicio
        ? moment(this.newSancion.fecha_inicio).format("DD/MM/YYYY hh:mm A")
        : "";
    },
    formattedFechaFin() {
      return this.newSancion.fecha_fin
        ? moment(this.newSancion.fecha_fin).format("DD/MM/YYYY hh:mm A")
        : "";
    },
  },
  methods: {
    ...mapActions("proveedores", ["getProviderLogo"]),
    validYears() {
      if (Number(this.selectedYear) < 1 || Number(this.selectedYear) > 5) this.selectedYear = '';
    },
    closeModal() {
      this.$emit("onClose");
      this.formClean();
    },
    setFechaFin() {
      this.newSancion.fecha_fin = moment(this.newSancion.fecha_inicio).add(
        this.selectedYear,
        "Y"
      );
    },
    setDescripcionSancion(id) {
      this.newSancion.id_descripcion_sancion = id;
    },
    setYearSancion(data) {
      this.selectedYear = data?.anios;
    },
    formClean() {
      this.$refs.select_desc.reset();
      Object.keys(this.newSancion).forEach((key) => {
        this.newSancion[key] = null;
      });
      this.$v.$reset();
    },
    async fetchProviderData() {
      let response = await this.services.AgreementMarco.getProviderAgreement(
        this.selectedProvider
      );
      if (response?.status == 200) {
        this.providerData = response?.data;
      }
    },
    async getTiposSanciones() {
      const { status, data } =
        await this.services.ContratoService.getTiposSanciones();

      if (status === 200) {
        this.tipos_sanciones = data;
      }
    },
    async guardarSanciones() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const {
          contrato: { id_forma_contratacion },
        } = this.seguimientoContrato;
        this.newSancion.id_model = this.$route.params.idContrato;
        this.newSancion.id_forma_contratacion = id_forma_contratacion;
        this.newSancion.fecha_inicio =
          moment(this.newSancion.fecha_inicio).format("YYYY/MM/DD hh:mm:ss");
        this.newSancion.fecha_fin = moment(this.newSancion.fecha_fin).format(
          "YYYY/MM/DD hh:mm:ss"
        );

        let formData = new FormData();
        Object.keys(this.newSancion).forEach((key) => {
          if (this.newSancion[key]) formData.append(key, this.newSancion[key]);
        });
        formData.append('years', this.selectedYear);

        const { status } = await this.services.ContratoService.postSanciones(
          this.selectedProvider,
          formData
        );
        if (status === 201) {
          this.closeModal();
          this.$emit("reloadSanciones");
          this.temporalAlert({
            show: true,
            message: "Sanción registrada",
            type: "success",
          });
        }
      }
    },
  },
  created() {
    this.fetchProviderData();
    this.getProviderLogo(this.selectedProvider);
    this.getTiposSanciones();
  },
};
</script>
<style lang="scss">
.v-list-item.v-list-item--highlighted:before {
  opacity: 0.1 !important;
}
</style>
