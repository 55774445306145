<template>
  <div class="pa-4 my-6">
    <v-btn
      v-if="!hideButton"
      class="mb-4"
      color="secondary"
      dark
      @click="showSancionesModal = true"
      >Agregar sanción</v-btn
    >
    <data-table-component
      :headers="headers"
      :items="items"
      v-models:select="filtrosSanciones.per_page"
      v-models:pagina="filtrosSanciones.page"
      :total_registros="filtrosSanciones.total_rows"
      @paginar="paginar"
    >
      <template v-slot:[`item.origen`]="{ item }">
        {{ item.ContratoOrden?.numero || item.ProcesoCompra?.codigo_proceso }}
      </template>
      <template v-slot:[`item.fecha_inicio`]="{ item }">
        {{ item.fecha_inicio | moment("DD/MM/YYYY") }}
      </template>
      <template v-slot:[`item.tiempo`]="{ item }">
        {{ getDays(item) || "" }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip label :color="item.estado ? 'vigente' : 'deshabilitado'">
          {{ item.estado ? "Activa" : "Inactiva" }}
        </v-chip>
      </template>
      <template v-slot:[`item.opciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="(showSancionesModal = true), (sancionSelected = item)"
              v-bind="attrs"
              v-on="on"
              icon
              color="primary"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span> Ver detalle </span>
        </v-tooltip>
      </template>
    </data-table-component>
    <ModalSancionesComponent
      :showModal="showSancionesModal"
      @onClose="closeModal"
      @reloadSanciones="getSanciones"
      :sancionSelected="sancionSelected"
      :lastSancion="lastSancion"
    />
    <!-- <dialogoConfirmacion
      :show="deleteModal"
      title="¿Desea remover el registro seleccionado?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="deleteModal = false"
      @confirm="deleteSancion()"
    /> -->
  </div>
</template>

<script>
import ModalSancionesComponent from "./ModalSancionesComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment";
export default {
  name: "SancionesComponent",
  components: {
    ModalSancionesComponent,
    dialogoConfirmacion,
    DataTableComponent,
  },
  props: {
    hideButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showSancionesModal: false,
    deleteModal: false,
    lastSancion: null,
    sancionSelected: {},
    headers: [
      {
        text: "Código",
        value: "codigo",
      },
      {
        text: "Origen",
        value: "origen",
      },
      {
        text: "Fecha de sanción",
        value: "fecha_inicio",
      },
      {
        text: "Estado de sanción",
        value: "estado",
      },
      {
        text: "Motivo de sanción",
        value: "observaciones",
      },
      {
        text: "Tiempo (días)",
        value: "tiempo",
      },
      {
        text: "Opciones",
        value: "opciones",
        sortable: false,
      },
    ],
    items: [],
  }),
  computed: {
    ...mapState("agregarInsumo", ["selectedProvider"]),
    ...mapState("seguimientoOrden", [
      "seguimientoContrato",
      "filtrosSanciones",
    ]),
  },
  methods: {
    ...mapActions("seguimientoOrden", ["getSansiones"]),
    ...mapMutations("seguimientoOrden", ["setFiltrosSanciones"]),
    getDays(item) {
      let start = moment(item.fecha_inicio);
      let end = moment(item.fecha_fin);
      return moment.duration(end.diff(start)).asDays();
    },
    closeModal() {
      this.showSancionesModal = false;
      this.sancionSelected = {};
      this.getSanciones();
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.setFiltrosSanciones({
        page: pagina,
        per_page: cantidad_por_pagina,
      });

      this.getSanciones();
    },
    async getSanciones() {
      const {
        contrato: { id_forma_contratacion },
      } = this.seguimientoContrato;
      const { status, data, headers } = await this.getSansiones({
        selectProvider: this.selectedProvider,
        id_forma_contratacion: id_forma_contratacion,
        ...this.filtrosSanciones,
      });
      if (status === 200) {
        this.items = data;
        this.setFiltrosSanciones({
          page: headers.page,
          per_page: headers.per_page,
          total_rows: headers.total_rows,
        });
        if (this.items[this.items.length - 1]) {
          const ultima = this.items.filter(
            (item) => item.id === Math.max(...this.items.map((item) => item.id))
          );
          if (ultima.length > 0) {
            this.lastSancion = moment(ultima[0].fecha_fin)
              .add(1, "d")
              .format("YYYY/MM/DD hh:mm");
          }
        }
      }
    },
    async deleteSancion() {
      const { status } = await this.services.ContratoService.deleteSanciones(
        this.sancionSelected
      );
      if (status === 204) {
        this.temporalAlert({
          show: true,
          message: "Sanción eliminada",
          type: "success",
        });
        this.getSanciones();
        this.deleteModal = false;
      }
    },
  },
  created() {
    this.getSanciones();
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-chip) {
  width: -webkit-fill-available !important;
  display: flex !important;
  justify-content: center !important;
}
</style>
