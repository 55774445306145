<template>
  <div>
    <v-dialog :max-width="750" v-model="showModal" @click:outside="closeModal">
      <v-card>
        <v-btn class="mt-2 ml-3" icon @click="closeModal">
          <v-icon left>mdi-close</v-icon>
        </v-btn>
        <section class="pt-0">
          <v-row class="my-4" align="center">
            <v-col cols="12" xl="5" lg="5" md="5" sm="5" xs="12" class="pb-0">
              <v-card-title class="mx-3 text-h5 secondary--text"
                >Generar una multa</v-card-title
              >
            </v-col>
            <v-col
              cols="12"
              xl="7"
              lg="7"
              md="7"
              sm="7"
              xs="12"
              :class="$vuetify.breakpoint.xs ? '' : 'pl-0'"
            >
              <div class="d-flex">
                <div class="text-center mr-4">
                  <p class="text-h4 mb-0">
                    ${{ metricasMultas.monto_contratado }}
                  </p>
                  <v-card-subtitle class="justify-center my-0 py-0">
                    <p class="my-0 py-0 px-4">Monto Contratado</p>
                  </v-card-subtitle>
                  <v-progress-linear
                    color="yellow"
                    rounded
                    value="100"
                    class="my-0 py-0"
                  ></v-progress-linear>
                </div>

                <div class="text-center ml-3">
                  <p class="text-h4 mb-0">
                    {{ metricasMultas.cantidad_multas }}
                  </p>
                  <v-card-subtitle class="justify-center my-0 py-0">
                    <p class="my-0 py-0 px-4">Multas Emitidas</p>
                  </v-card-subtitle>
                  <v-progress-linear
                    color="red"
                    rounded
                    value="100"
                    class="my-0 py-0"
                  ></v-progress-linear>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-card-text class="pb-0">
            <section>
              <v-row>
                <v-col cols="6">
                  <v-select
                    label="Etapa *"
                    outlined
                    :items="etapasList"
                    :error-messages="idEtapaErrors"
                    @blur="$v.id_etapa.$touch()"
                    v-model="id_etapa"
                    item-value="id"
                    item-text="etapa"
                  >
                  </v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Porcentaje de la multa *"
                    outlined
                    type="number"
                    append-icon="mdi-percent-outline"
                    v-model="multa.porcentaje"
                    :error-messages="porcentajeErrors"
                    @blur="$v.multa.porcentaje.$touch()"
                    @keydown="filterKey"
                    @input="restrictDecimalPorcentaje"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedDate"
                        label="Fecha *"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="fechaErrors"
                        @blur="$v.fecha.$touch()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fecha"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    ref="menuHora"
                    v-model="menuHora"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="hora"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedHora"
                        label="Hora *"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="horaErrors"
                        @blur="$v.hora.$touch()"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menuHora"
                      v-model="hora"
                      full-width
                      @click:minute="$refs.menuHora.save(hora)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Monto *"
                    outlined
                    type="number"
                    prepend-inner-icon="mdi-currency-usd"
                    v-model="multa.monto"
                    :error-messages="montoErrors"
                    @blur="$v.multa.monto.$touch()"
                    @keydown="filterKey"
                    @input="restrictDecimal"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-textarea
                label="Justificación *"
                outlined
                maxlength="250"
                v-model="multa.justificacion"
                :error-messages="justificacionErrors"
                @blur="$v.multa.justificacion.$touch()"
              ></v-textarea>
              <v-col cols="8" class="pl-0 pt-0">
                <v-file-input
                  outlined
                  label="Acta de no cumplimiento *"
                  prepend-icon=""
                  append-icon="mdi-paperclip"
                  accept=".pdf"
                  v-model="multa.comprobante"
                  :error-messages="actaErrors"
                  @blur="$v.multa.comprobante.$touch()"
                ></v-file-input>
              </v-col>
            </section>
          </v-card-text>
          <v-card-actions>
            <section class="pt-0">
              <v-btn class="mx-3" color="secondary" outlined @click="closeModal"
                >Cerrar</v-btn
              >
              <v-btn class="mx-3" color="secondary" @click="guardarDatos"
                >Guardar</v-btn
              >
            </section>
          </v-card-actions>
        </section>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import moment from "moment";
import { mapState, mapMutations, mapActions } from "vuex";

function validarMonto() {
  return this.multa.monto > 0;
}

export default {
  name: "ModalMultasComponent",
  props: {
    showModal: Boolean,
    metricasMultas: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hora: null,
      fecha: null,
      maxFecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      menu: false,
      menuHora: false,
      id_etapa: null,
      multa: {
        fecha_hora: null,
        porcentaje: null,
        monto: null,
        justificacion: null,
        id_tipo_movimiento: null,
        comprobante: null,
      },
    };
  },

  validations: {
    multa: {
      porcentaje: { required },
      monto: { required, validarMonto },
      justificacion: { required, minLength: minLength(20) },
      comprobante: { required },
    },
    fecha: {
      required,
    },
    hora: {
      required,
    },
    id_etapa: { required },
  },
  computed: {
    ...mapState("procesoCompra", ["etapasList"]),
    formattedDate() {
      return this.fecha ? moment(this.fecha).format("DD/MM/YYYY") : "";
    },
    formattedHora() {
      return this.hora
        ? moment(this.hora, "HH:mm:ss aa").format("hh:mm a")
        : "";
    },
    montoErrors() {
      const errors = [];
      if (!this.$v.multa.monto.$dirty) return errors;
      !this.$v.multa.monto.required && errors.push("Monto es obligatorio");
      !this.$v.multa.monto.validarMonto &&
        errors.push("Monto debe ser mayor a cero");

      return errors;
    },
    fechaErrors() {
      const errors = [];
      if (!this.$v.fecha.$dirty) return errors;
      !this.$v.fecha.required && errors.push("Fecha es obligatorio");
      if (!this.$v.hora.$dirty) return errors;

      return errors;
    },
    horaErrors() {
      const errors = [];
      if (!this.$v.hora.$dirty) return errors;
      !this.$v.hora.required && errors.push("Hora es obligatorio");

      return errors;
    },
    justificacionErrors() {
      const errors = [];
      if (!this.$v.multa.justificacion.$dirty) return errors;
      !this.$v.multa.justificacion.required &&
        errors.push("Justificación es obligatorio");
      !this.$v.multa.justificacion.minLength &&
        errors.push("Justificación debe tener al menos 20 caracteres");
      return errors;
    },

    porcentajeErrors() {
      const errors = [];
      if (!this.$v.multa.porcentaje.$dirty) return errors;
      !this.$v.multa.porcentaje.required &&
        errors.push("Porcentaje es obligatorio");

      return errors;
    },
    actaErrors() {
      const errors = [];
      if (!this.$v.multa.comprobante.$dirty) return errors;
      !this.$v.multa.comprobante.required &&
        errors.push("Acta de no cumplimiento es obligatorio");
      return errors;
    },
    idEtapaErrors() {
      const errors = [];
      if (!this.$v.id_etapa.$dirty) return errors;
      !this.$v.id_etapa.required && errors.push("Etapa es obligatorio");
      return errors;
    },
  },

  methods: {
    ...mapMutations("seguimientoOrden", ["setMontos"]),
    ...mapActions("seguimientoOrden", ["getPagos"]),
    closeModal() {
      this.$emit("onClose");
      this.formClean();
    },
    filterKey(e) {
      const key = e.key;
      if (key === "-") return e.preventDefault();
      if (key === "+") return e.preventDefault();
      if (key === "e") return e.preventDefault();
    },

    formClean() {
      Object.keys(this.multa).forEach((key) => {
        this.multa[key] = null;
      });
      this.fecha = null;
      this.hora = null;
      this.id_etapa = null;
      this.$v.$reset();
    },
    restrictDecimal() {
      this.multa.monto = this.multa.monto.match(/^\d+\.?\d{0,2}/);
    },
    restrictDecimalPorcentaje() {
      this.multa.porcentaje = this.multa.porcentaje.match(/^\d+\.?\d{0,2}/);
    },
    async getMetricasPagos() {
      const { status, data } = await this.services.ContratoService.getMontosPagos(this.$route.params.idContrato);
      if (status === 200) {
        this.setMontos(data);
        this.metricasPagos = this.montos;
      }
    },
    async guardarDatos() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.multa.fecha_hora = `${this.fecha} ${this.hora}`;
        this.multa.id_tipo_movimiento = 2;

        let formData = new FormData();
        Object.keys(this.multa).forEach((key) => {
          if (this.multa[key]) formData.append(key, this.multa[key]);
        });
        const { status } =
          await this.services.ContratoService.postContratoPagos(
            this.id_etapa,
            formData
          );
        if (status === 201) {
          this.closeModal();
          this.formClean();
          this.$emit("reloadList");
          this.temporalAlert({
            show: true,
            message: "Multa Registrada",
            type: "success",
          });
          this.getMetricasPagos();
          this.getPagos({ idContrato: this.$route.params.idContrato });
        }
      }
    },
    created() {},
  },
};
</script>

<style lang="scss" scoped></style>
