<template>
  <section  class="mx-sm-4">
    <v-btn color="primary" class="px-0" text @click="dialog = true">
      <p class="ma-0 mr-4">La Ceiba, S.A. de C.V.</p>
      <v-icon>mdi-eye</v-icon>
    </v-btn>
    <v-rating
      v-show="mostrarEstrellas"
      background-color="secundary"
      color="primary"
      empty-icon="mdi-star-outline"
      full-icon="mdi-star"
      length="5"
      readonly
      size="20"
      :value="4"
    ></v-rating>

    <v-dialog v-model="dialog" width="900">
      <v-card>
        <v-card-title class="text-h6 secondary--text">
          Perfil del proveedor
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-icon size="242px" color="primary">mdi-image-outline</v-icon>
            </v-col>
            <v-col cols="12" md="4">
              <p class="font-weight-medium primary--text mb-0">
                Nombre del proveedor:
              </p>
              <p class="text-subtitle-1 font-weight-bold primary--text">
                La Ceiba, S.A. de C.V.
              </p>
              <p class="font-weight-medium primary--text mb-0">
                Tipo de persona
              </p>
              <p class="text-subtitle-1 font-weight-bold primary--text">
                Persona natural
              </p>
              <p class="font-weight-medium primary--text mb-0">DUI:</p>
              <p class="text-subtitle-1 font-weight-bold primary--text">
                043423128
              </p>
              <p class="font-weight-medium primary--text mb-0">NIT:</p>
              <p class="text-subtitle-1 font-weight-bold primary--text">
                06140912951429
              </p>
              <p class="font-weight-medium primary--text mb-0">Teléfono:</p>
              <p class="text-subtitle-1 font-weight-bold primary--text">
                7777-7777
              </p>
              <p class="font-weight-medium primary--text mb-0">
                Correo electrónico:
              </p>
              <p class="text-subtitle-1 font-weight-bold primary--text">
                correo.correo@gmail.com
              </p>
            </v-col>
            <v-col cols="12" md="4">
              <p class="font-weight-medium primary--text mb-0">
                Nombre comercial:
              </p>
              <p class="text-subtitle-1 font-weight-bold primary--text">
                Proveedor
              </p>
              <p class="font-weight-medium primary--text mb-0">Género:</p>
              <p class="text-subtitle-1 font-weight-bold primary--text">
                Masculino
              </p>
              <p class="font-weight-medium primary--text mb-0">Sitio web:</p>
              <p class="text-subtitle-1 font-weight-bold primary--text">
                www.proveedor.com.sv
              </p>
              <p class="font-weight-medium primary--text mb-0">
                Clasificación de la empresa:
              </p>
              <p class="text-subtitle-1 font-weight-bold primary--text">
                www.proveedor.com.sv
              </p>
              <p class="font-weight-medium primary--text mb-0">Dirección:</p>
              <p class="text-subtitle-1 font-weight-bold primary--text">
                El Salvador, San Salvador, La Libertad, La Libertad, 1 calle
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" outlined @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
export default {
  name: "PerfilProveedorComponent",
  props: {
    mostrarEstrellas: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
  }),
};
</script>

<style scoped lang="css">
.v-rating .v-icon {
  padding: 0 !important;
}
</style>
